import { boundClass } from 'autobind-decorator';
import EventEmitter from 'eventemitter3';
import { SubscriptionStatus, TrackedUser } from './UserTracker/types';
import { UserContextEvents } from './types';

export interface UserContextInterface {
  getUserId(): string;
  getUser(): TrackedUser;
  setUser(user: TrackedUser): void;
  getSubscriptionStatus(): SubscriptionStatus;
  getOrganizationId(): string;
  getUserOrganizationIds(): string[];
  setOrganizationId(newOrganizationId: string);
  setUserOrganizationIds(newUserOrganizationIds: string[]);
  setSubscriptionStatus(newSubscription: SubscriptionStatus);
  getEventEmitter(): EventEmitter;
}

@boundClass
class UserContext implements UserContextInterface {
  private organizationId: string;
  private userOrganizationIds: string[] = [];
  private user: TrackedUser;
  private eventEmitter: EventEmitter;
  private subscriptionStatus: SubscriptionStatus;

  constructor(EventEmitterContructor = EventEmitter) {
    this.eventEmitter = new EventEmitterContructor();
  }

  setUser(user: TrackedUser) {
    this.user = user;
    this.eventEmitter.emit(UserContextEvents.USER_CHANGED, user);
  }

  setSubscriptionStatus(newSubscription: SubscriptionStatus) {
    this.subscriptionStatus = newSubscription;
    this.eventEmitter.emit(UserContextEvents.SUBSCRIPTION_STATUS_CHANGED, newSubscription);
  }

  setOrganizationId(newOrganizationId: string) {
    this.organizationId = newOrganizationId;
    this.eventEmitter.emit(UserContextEvents.ORGANIZATION_CHANGED, newOrganizationId);
  }

  setUserOrganizationIds(newOrganizationIds: string[]) {
    this.userOrganizationIds = newOrganizationIds;
    this.eventEmitter.emit(UserContextEvents.USER_ORGANIZATIONS_CHANGED, newOrganizationIds);
  }

  getOrganizationId(): string {
    return this.organizationId;
  }

  getUserOrganizationIds(): string[] {
    return this.userOrganizationIds || [];
  }

  getSubscriptionStatus(): SubscriptionStatus {
    return this.subscriptionStatus;
  }

  getUser(): TrackedUser {
    return { ...this.user };
  }

  getUserId(): string {
    return this.user.id;
  }

  getEventEmitter() {
    return this.eventEmitter;
  }
}

export default UserContext;
