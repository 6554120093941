import { Id, LoggerInterface } from '../types';

export interface UserTrackerOptionsInterface {
  timestampNowFunction?: () => number;
  logger?: LoggerInterface;
  trackUrl?: string;
  sendIntervalMiliseconds?: number;
  browser?: string;
  platform?: string;
  isUserTrackerEnabled?: boolean;
}

export interface EventProperties {
  browser: string;
  platform: string;
}

export interface TrackedUser {
  id: Id;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: number;
}

export interface TrackerEvent {
  id: Id;
  name: string;
  epoch: number;
  properties?: EventProperties;
  user?: TrackedUser;
  sessionId?: Id;
  organizationId?: Id;
  userOrganizationIds?: Id[];
  subscriptionStatus?: SubscriptionStatus;
}

export type TrackerCallback = (...args: any[]) => void;

export enum SubscriptionStatus {
  ACTIVE = 'active',
  IN_TRIAL = 'in_trial',
  NON_RENEWING = 'non_renewing',
  CANCELLED = 'cancelled',
  FUTURE = 'future',
}
