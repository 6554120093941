import * as async from 'async';
import { boundClass } from 'autobind-decorator';
import isDev from 'common/utils/isDevEnv';
import { pick } from 'lodash';
import { AnyDict, Id, LoggerInterface } from '../types';
import { FetchOptionsInterface, HttpAgentInterface } from '../utils/HttpAgent';
import generateUuid from '../utils/generateUuid';
import timestampNow from '../utils/timestampNow';
import { QueueInterface } from './interfaces/QueueInterface';
import { UserTrackerInterface } from './interfaces/UserTrackerInterface';
import { SubscriptionStatus, TrackedUser, TrackerEvent, UserTrackerOptionsInterface } from './types';

export * from './types';

const defaultOptions: UserTrackerOptionsInterface = {
  timestampNowFunction: timestampNow,
  logger: console,
  trackUrl: '/usage-tracker',
  sendIntervalMiliseconds: 500,
  isUserTrackerEnabled: true,
};

@boundClass
class UserTracker implements UserTrackerInterface {
  private options: UserTrackerOptionsInterface;
  private logger: LoggerInterface;
  private isUploading: boolean = false;
  private isUserTrackerEnabled: boolean = true;
  private user: TrackedUser;
  private sessionId: Id;
  private organizationId: Id;
  private userOrganizationIds: Id[];
  private subscriptionStatus: SubscriptionStatus;
  private queue: QueueInterface<TrackerEvent>;
  private httpAgent: HttpAgentInterface;

  constructor(
    httpAgent: HttpAgentInterface,
    queue: QueueInterface<TrackerEvent>,
    userTrackerOptions: UserTrackerOptionsInterface
  ) {
    if (!httpAgent) {
      throw new Error('HttpClient in UserTracker is missing');
    }

    if (!queue) {
      throw new Error('Queue in UserTracker is missing');
    }

    this.options = Object.assign({}, defaultOptions, userTrackerOptions);
    this.logger = this.options.logger;
    this.isUserTrackerEnabled = this.options.isUserTrackerEnabled;
    this.queue = queue;
    this.httpAgent = httpAgent;
  }

  private startUploading(error: Error) {
    // eslint-disable-line
    if (error) {
      this.logger.error(error);
      return false;
    }

    if (this.isUploading) {
      return false;
    }

    this.isUploading = true;
    setTimeout(() => {
      this.queue.getAll(this.sendEvents);
    }, this.options.sendIntervalMiliseconds);
  }

  private sendEvents(error: Error, events: TrackerEvent[]) {
    // eslint-disable-line
    if (error || events.length === 0) {
      return this.finishUploading(error);
    }

    const validEvents = Object.values(events).filter(this.validateEvent);
    const eventsPutRequest = this.createEventsPutRequest(validEvents);

    if (!eventsPutRequest) {
      return false;
    }

    this.httpAgent.request(this.options.trackUrl, eventsPutRequest, async (requestError, response) => {
      if (requestError || response.status !== 200) {
        return await this.createRequestError(requestError, response);
      }

      return this.removeEventsFromQueue(events);
    });
  }

  private validateEvent(event: TrackerEvent): boolean {
    return Boolean(event && event.name && event.id && event.epoch);
  }

  private removeEventsFromQueue(events: TrackerEvent[]) {
    async.each(
      events,
      (event, callback) => {
        this.queue.remove(event, callback);
      },
      (error) => {
        if (error) {
          return this.finishUploading(error);
        }

        return setTimeout(() => {
          this.queue.getAll(this.sendEvents);
        }, this.options.sendIntervalMiliseconds);
      }
    );
  }

  private async createRequestError(requestError: Error, response: Response) {
    // eslint-disable-line
    if (requestError) {
      return this.finishUploading(requestError);
    }

    try {
      const responseJson = await response.json();
      this.finishUploading(responseJson);
    } catch (error) {
      this.finishUploading(error);
    }
  }

  private finishUploading(error: Error) {
    if (error) {
      this.logger.error(error);
    }

    this.isUploading = false;
  }

  private createEventsPutRequest(events: TrackerEvent[]): FetchOptionsInterface {
    try {
      return {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ events }, null, 2),
        credentials: 'include',
      };
    } catch (error) {
      this.finishUploading(error);
      return null;
    }
  }

  track(eventName: string, eventProperties: AnyDict = {}): boolean {
    if (isDev()) {
      console.log(`Tracked event: ${eventName}`, eventProperties);
    }
    if (!this.isUserTrackerEnabled) {
      return false;
    }

    if (!eventName) {
      this.logger.error(new Error(`Unrecognized UserTracker event: ${eventName}`));
      return false;
    }

    const { browser, platform } = this.options;

    const event: TrackerEvent = {
      id: generateUuid(),
      epoch: this.options.timestampNowFunction(), // key for timestampNowFunction is named epoch because of backend naming convention (on BE epoch means time in milliseconds)
      name: eventName,
      properties: Object.assign(
        {},
        { browser, platform, subscriptionStatus: this.subscriptionStatus },
        eventProperties
      ),
      user: this.user,
      sessionId: this.sessionId,
      organizationId: this.organizationId,
      userOrganizationIds: this.userOrganizationIds,
      subscriptionStatus: this.subscriptionStatus,
    };
    this.queue.push(event, this.startUploading);
  }

  setUser(newUser: TrackedUser) {
    this.user = pick(newUser, ['id', 'createdAt', 'firstName', 'lastName', 'email']);
  }

  setSessionId(newSessionId: Id) {
    this.sessionId = newSessionId;
  }

  setOrganizationId(newOrganizationId: Id) {
    this.organizationId = newOrganizationId;
  }

  setUserOrganizationIds(newUserOrganizationIds: Id[]) {
    this.userOrganizationIds = newUserOrganizationIds;
  }

  setSubscriptionStatus(newStatus: SubscriptionStatus) {
    this.subscriptionStatus = newStatus;
  }

  enable() {
    this.isUserTrackerEnabled = true;
  }

  disable() {
    this.isUserTrackerEnabled = false;
  }

  get userId() {
    return this.user.id;
  }
}

export default UserTracker;
